<template>
  <text-field
    v-model.number="modelWrapper"
    type="number"
    class="schema-form-field-square"
    :label="label"
    :error-messages="errors"
    v-on="inheritedListeners"
  >
    <template #append>
      {{ $t(areaUnit) }}
      <sup class="schema-form-field-square__sup">
        2
      </sup>
    </template>
  </text-field>
</template>

<script>
import TextField from '@/components/TextField.vue';
import { createModelWrapper, getInheritedListeners } from '../../utils/components';

// Components

const AREA_UNIT_NAME = {
  m: 'si.m',
  ft: 'si.ft',
};

export default {
  name: 'SchemaFormFieldSquare',

  components: { TextField },

  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
    areaUnit() {
      return AREA_UNIT_NAME[this.payload?.areaUnit] || 'si.m';
    },
  },
};
</script>

<style lang="scss" scoped>
.schema-form-field-square {
  &__sup {
    top: 0;
  }
}
</style>
