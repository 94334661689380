<template>
  <v-menu v-model="showMenu" offset-y class="multi-select" max-height="304" :close-on-content-click="false">
    <template #activator="{ on, attrs, value: menuIsOpen }">
      <div v-bind="attrs" v-on="on">
        <!-- Продублировал attrs и on чтобы курсор отображался корректно -->
        <text-field
          ref="input"
          readonly
          class="multi-select__select-field"
          :label="label"
          :value="textSelectedValues"
          :loading="loading"
          :error-messages="errorMessages"
          v-bind="{ ...attrs, ...$attrs }"
          @click:append="on['click']"
          v-on="on"
        >
          <template #append>
            <v-icon class="multi-select__arrow" :class="{ 'multi-select__arrow--down': menuIsOpen }"
              >mdi-menu-down</v-icon
            >
          </template>
        </text-field>
      </div>
    </template>

    <v-list>
      <div v-if="!hiddenSearch" class="multi-select__search-field">
        <text-field
          v-model="searchModel"
          prepend-inner-icon="mdi-magnify"
          class="multi-select__search mt-0 pt-0 mb-2"
          hide-details
          :placeholder="$t(placeholderSearch)"
        />
      </div>

      <div v-if="canSelectAll">
        <v-list-item
          v-if="!search"
          ripple
          active-class="primary--text"
          class="primary--text"
          :input-value="isSelectedAll"
          @click="toggleAll"
        >
          <v-list-item-action>
            <v-icon color="primary">{{ selectAllIcon }}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            {{ $t('button.select_all') }}
          </v-list-item-content>
        </v-list-item>
      </div>

      <v-list-item
        v-for="item in items"
        :key="getKey(item)"
        ripple
        active-class="primary--text"
        class="primary--text"
        :input-value="getSelectStatus(item)"
        @click="toggleItem(item)"
      >
        <v-list-item-action>
          <base-checkbox :input-value="getSelectStatus(item)" :ripple="false" />
        </v-list-item-action>

        <v-list-item-content v-if="translate">
          {{ $t(getText(item)) }}
        </v-list-item-content>

        <v-list-item-content v-else>
          {{ getText(item) }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
// utils
import i18n from '@/plugins/i18n';
import { createModelWrapper } from '@/utils/components';
import { isFunction, isEqual } from '@/utils/common';
import { clone } from '@/utils/clone';
import getTextWidth from '@/utils/text';

// Components
import TextField from '@/components/TextField.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';

export default {
  name: 'MultiSelect',

  components: { TextField, BaseCheckbox },

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      required: true,
    },
    items: { type: Array, required: true },
    allMessage: { type: String, required: true },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    canSelectAll: { type: Boolean, default: true },
    search: { type: String, default: '' },
    itemText: { type: [Function, String], default: 'name' },
    translate: { type: Boolean, default: false },
    keyValue: { type: [String, Function], default: 'id' },
    label: { type: String, default: '' },
    isFiltered: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    hiddenSearch: { type: Boolean, default: false },
    placeholderSearch: { type: String, default: 'label.search' },
  },

  data() {
    return {
      selectAll: false,
      selectApplication: false,
      showMenu: false,
    };
  },

  computed: {
    valueModel: createModelWrapper('value', 'input'),
    searchModel: createModelWrapper('search', 'update:search'),

    excludedItems() {
      return this.items.filter(
        item => !this.valueModel.exclude.find(excludeItem => this.getKey(excludeItem) === this.getKey(item))
      );
    },

    includedItems() {
      return this.valueModel.include;
    },

    textSelectedValues() {
      if (this.isSelectedAll) {
        return this.allMessage;
      }

      const inputWidth = this.$refs.input?.$el.querySelector('input').clientWidth || 216;
      let resultText = '';
      let textDoesNotFit = false;
      const items = this.isExcluded ? this.excludedItems : this.includedItems;

      const textItems = items.map(item => this.getText(item));
      let sourceText = '';

      for (let i = 0; i < textItems.length; i += 1) {
        const itemsLength = textItems.slice(i + 1).length;
        if (this.translate) {
          sourceText = `${i18n.t(textItems.slice(0, i + 1).join(', '))} (+${itemsLength})`;
        } else {
          sourceText = `${textItems.slice(0, i + 1).join(', ')} (+${itemsLength})`;
        }
        const textWidth = getTextWidth(sourceText, '16px Roboto');

        if (textWidth > inputWidth) {
          textDoesNotFit = true;

          break;
        }

        resultText = sourceText;
      }

      if (resultText === '' && textDoesNotFit) {
        resultText = `(+${textItems.length})`;
      }

      if (this.translate) {
        return textDoesNotFit ? resultText : i18n.t(textItems.join(', '));
      }

      return textDoesNotFit ? resultText : textItems.join(', ');
    },

    isExcluded() {
      return this.valueModel.exclude.length > 0;
    },

    isSelectedAll() {
      if (this.isFiltered) {
        const firstSet = new Set(this.valueModel.include.map(item => this.getKey(item)).sort((a, b) => a - b));
        const secondSet = new Set(this.items.map(item => this.getKey(item)).sort((a, b) => a - b));
        return isEqual(firstSet, secondSet);
      }

      return this.valueModel.all;
    },

    isSelectedSome() {
      return (this.valueModel.include?.length > 0 || this.valueModel.exclude?.length > 0) && !this.isSelectedAll;
    },

    selectAllIcon() {
      if (this.isSelectedAll) return 'mdi-close-box';
      if (this.isSelectedSome) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },

    displayedValue() {
      if (this.isSelectedAll) {
        return this.allMessage;
      }

      return `${this.value.include
        .slice(0, this.$options.sliceLength)
        .map(item => this.getText(item))
        .join(', ')} `;
    },
  },

  methods: {
    getSelectStatus(item) {
      if (this.isSelectedAll) {
        this.selectApplication = true;
        return true;
      }
      this.selectApplication = false;

      if (this.isExcluded) {
        const foundInExclude = Boolean(
          this.valueModel.exclude.find(excludeItem => this.getKey(excludeItem) === this.getKey(item))
        );

        return !foundInExclude;
      }

      const foundInInclude = Boolean(
        this.valueModel.include.find(includeItem => this.getKey(includeItem) === this.getKey(item))
      );

      if (this.getKey(item) === 'application' && this.selectApplication) {
        return true;
      }

      return foundInInclude;
    },

    getKey(item) {
      if (isFunction(this.keyValue)) {
        return this.keyValue(item);
      }

      return item?.[this.keyValue] || item;
    },

    setIncludeItems(exludeItems) {
      return this.items.filter(
        item => !exludeItems.find(excludeItem => this.getKey(excludeItem) === this.getKey(item))
      );
    },

    toggleItem(item) {
      const isSelected = this.getSelectStatus(item);

      if (isSelected) {
        if ((this.isExcluded || this.isSelectedAll) && !this.isFiltered) {
          if (this.getKey(item) === 'application') {
            this.selectApplication = false;

            let include = [];

            this.items.forEach(el => {
              if (this.getKey(el) !== 'application') {
                include.push(el);
              }

              this.valueModel.exclude.forEach(excludeItem => {
                include = include.filter(includeItem => this.getKey(excludeItem) !== this.getKey(includeItem));
              });
            });

            this.valueModel = {
              include,
              all: false,
              exclude: [],
            };
          } else {
            this.valueModel = {
              include: this.setIncludeItems([...this.valueModel.exclude, clone(item)]),
              all: false,
              exclude: [],
            };
          }

          return;
        }

        this.valueModel = {
          exclude: [],
          all: false,
          include: this.valueModel.include.filter(valueItem => this.getKey(item) !== this.getKey(valueItem)),
        };

        return;
      }

      if (this.isExcluded || this.selectApplication) {
        const exclude = this.valueModel.exclude.filter(valueItem => this.getKey(item) !== this.getKey(valueItem));
        this.valueModel = {
          ...this.valueModel,
          all: exclude.length === 0,
          exclude,
        };

        return;
      }

      if (this.getKey(item) === 'application') {
        this.selectApplication = true;
        let exclude = [];

        this.items.forEach(el => {
          if (this.getKey(el) !== 'application') {
            exclude.push(el);
          }

          this.valueModel.include.forEach(includeItem => {
            exclude = exclude.filter(exludeItem => this.getKey(exludeItem) !== this.getKey(includeItem));
          });
        });

        this.valueModel = {
          ...this.valueModel,
          exclude,
          include: [],
        };

        return;
      }

      this.valueModel = { ...this.valueModel, include: [...this.valueModel.include, clone(item)] };
    },

    toggleAll() {
      if (this.isFiltered) {
        if (this.valueModel.include.length > 0) {
          this.valueModel = { include: [], exclude: [], all: false };
          return;
        }

        this.valueModel = { include: clone(this.items), exclude: [], all: false };
        return;
      }

      if (this.valueModel.all) {
        this.selectApplication = false;
      }

      if (!this.valueModel.all) {
        this.showMenu = false;
      }

      this.valueModel = { include: [], exclude: [], all: !this.valueModel.all };
    },

    checkCounterDisplay() {
      return this.valueModel.include.length > this.sliceLength && !this.isSelectedAll;
    },

    getText(item) {
      if (typeof this.itemText === 'string') {
        return item[this.itemText];
      }

      if (isFunction(this.itemText)) {
        return this.itemText(item);
      }

      return item;
    },
  },

  sliceLength: 3,
};
</script>

<style lang="scss">
.multi-select {
  max-height: 304px;

  &__arrow {
    &--down {
      transform: rotate(180deg);
    }
  }

  &__select-field {
    .v-input__slot {
      padding-left: 12px;
      padding-bottom: 6px;
    }

    input {
      text-overflow: ellipsis;
    }
  }

  .v-label {
    left: 12px !important;
  }

  &__search-field {
    position: sticky;
    background: $--white-color-0;
    top: 0;
    z-index: 1;
  }

  &__search {
    .v-input__prepend-inner {
      margin: 0 !important;
      margin-top: 5px !important;
    }

    .v-input__slot {
      padding-left: 12px;
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  &__menu {
    .v-list-item {
      .v-list-item__title {
        color: rgba($--black-color-0, 0.5) !important;
      }

      &--active {
        .v-list-item__title {
          color: $--black-color-0 !important;
        }
      }

      &__action {
        margin-right: 8px !important;
      }
    }

    .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
      color: #fff !important;
    }

    .v-input--selection-controls__input .v-icon {
      color: $--blue-color-60;
    }
  }
}
</style>
