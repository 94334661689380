<template>
  <v-menu
    ref="menu"
    v-model="menuIsOpen"
    content-class="form-field-date"
    :close-on-content-click="false"
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <!-- Решает проблему, что не открывается календарь при клике на label -->
      <div v-bind="attrs" v-on="on">
        <text-field
          class="form-field-date"
          :value="translatedModel"
          :label="label"
          :clearable="clearable"
          prepend-inner-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          @input="clear"
        >
          <template v-if="tooltip.text" #append>
            <v-tooltip top max-width="250">
              <template #activator="{ on: textOn, attrs: textAttrs }">
                <v-icon color="primary" v-bind="textAttrs" v-on="textOn">{{ tooltip.icon }}</v-icon>
              </template>

              <span>{{ $t(tooltip.text) }}</span>
            </v-tooltip>
          </template>
        </text-field>
      </div>
    </template>

    <v-date-picker
      v-model="dateModel"
      :type="payload.type"
      :locale="$i18n.locale"
      scrollable
      :title-date-format="titleDateFormat"
      :range="isMultiple"
      :min="min"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menuIsOpen = false">
        Cancel
      </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(dateModel)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
// node_modules
import format from 'date-fns/format';

// Utils
import { getInheritedListeners } from '@/utils/components';
import { translateDate } from '@/utils/dateFormatting';

// Services
import timeService from '@/services/time';

// Components
import TextField from '@/components/TextField.vue';

const DEFAULT_TYPE = 'date';

export default {
  name: 'SchemaFormFieldDate',

  components: { TextField },

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: () => ({ type: DEFAULT_TYPE }),
    },
    onlyFutureDate: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object,
      default: () => ({ text: '', icon: '' }),
    },
  },

  data() {
    return {
      menuIsOpen: false,
      dates: this.multiple || this.payload.multiple ? this.value?.split(' - ') : [],
      date: this.multiple || this.payload.multiple ? '' : this.value,
      currentDate: new Date(),
    };
  },

  computed: {
    modelWrapper: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    },

    min() {
      if (this.payload?.minDate) return this.payload.minDate;
      if (!this.onlyFutureDate) return undefined;

      return format(this.currentDate, 'yyyy-MM-dd');
    },

    isMultiple() {
      return this.multiple || this.payload.multiple;
    },

    dateModel: {
      get() {
        if (this.isMultiple) {
          return this.dates?.slice().sort((a, b) => new Date(a) - new Date(b));
        }

        return this.date;
      },

      set(value) {
        if (this.isMultiple) {
          this.dates = value;

          this.modelWrapper = this.dates
            .slice()
            .sort((a, b) => new Date(a) - new Date(b))
            .join(' - ');

          return;
        }

        this.date = value;
        this.modelWrapper = value;
      },
    },

    translatedModel() {
      if (this.isMultiple) {
        return this.dateModel
          ?.map(date => {
            return translateDate(this.formatDate(date));
          })
          .join(' - ');
      }

      return this.dateModel ? translateDate(this.formatDate(this.dateModel)) : '';
    },

    inheritedListeners: getInheritedListeners(['input']),
  },

  watch: {
    menuIsOpen(newState) {
      if (!newState && this.isMultiple && this.dates?.length === 1) {
        this.dates = [...this.dates, ...this.dates];
        this.modelWrapper = this.dates.join(' - ');
      }
    },

    value(newDate) {
      if (!newDate) {
        this.date = '';
        this.dates = [];
      } else if (this.isMultiple) {
        this.date = '';
        this.dates = this.value?.split(' - ') ?? [];
      } else {
        this.date = this.value;
        this.dates = [];
      }
    },
  },

  mounted() {
    timeService.subscribe(this.setDate);
  },

  beforeDestroy() {
    timeService.unsubscribe(this.setDate);
  },

  methods: {
    formatDate(date) {
      const noFormatDate = new Date(date);

      return format(noFormatDate, 'd L yyyy');
    },

    titleDateFormat(date) {
      if (this.isMultiple) {
        return date.map(localDate => translateDate(this.formatDate(localDate))).join(' - ');
      }

      return translateDate(this.formatDate(date));
    },

    setDate(date) {
      this.currentDate = date;
    },

    clear() {
      this.modelWrapper = '';
    },
  },
};
</script>

<style lang="scss">
.form-field-date {
  min-width: 290px !important;

  .v-input__append-inner {
    width: 28px;
    height: 24px;
  }
}
</style>
