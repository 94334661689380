import client from '@/http/client';
import { download, repackResponseFile } from '@/utils/common';

const BASE_URL = '/announcements';

class AnnouncementService {
  // eslint-disable-next-line class-methods-use-this
  delete(id) {
    return client.delete(`${BASE_URL}/${id}/`);
  }

  // eslint-disable-next-line class-methods-use-this
  getAllForClients({ orderBy, search, limit, offset, config } = {}) {
    return client.get(`${BASE_URL}/clients/`, {
      ...config,
      params: {
        orderBy,
        search,
        limit,
        offset,
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getAllForEmployees({ status, orderBy, search, limit, offset, config } = {}) {
    return client.get(`${BASE_URL}/employees/`, {
      ...config,
      params: {
        status,
        orderBy,
        search,
        limit,
        offset,
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getDefaultImages() {
    return client.get(`${BASE_URL}/images/default/`);
  }

  // eslint-disable-next-line class-methods-use-this
  uploadImage(image) {
    const formData = new FormData();
    formData.append('image', image);
    return client.post(`${BASE_URL}/images/`, formData);
  }

  // eslint-disable-next-line class-methods-use-this
  getById(id) {
    return client.get(`${BASE_URL}/${id}/`);
  }

  // eslint-disable-next-line class-methods-use-this
  getForClientById(id) {
    return client.get(`${BASE_URL}/clients/${id}/`);
  }

  // eslint-disable-next-line class-methods-use-this
  create({
    dispatchTime,
    title,
    content,
    defaultImage,
    image,
    isPushNotificationEnabled,
    isEmailNotificationEnabled,
    status,
    projects,
    buildings,
    units,
    clients,
  } = {}) {
    return client.post(`${BASE_URL}/`, {
      dispatchTime,
      title,
      content,
      defaultImage,
      image,
      isPushNotificationEnabled,
      isEmailNotificationEnabled,
      status,
      projects,
      buildings,
      units,
      clients,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  update({
    id,
    dispatchTime,
    title,
    content,
    defaultImage,
    image,
    isPushNotificationEnabled,
    isEmailNotificationEnabled,
    status,
    projects,
    buildings,
    units,
    clients,
  } = {}) {
    return client.patch(`${BASE_URL}/${id}/`, {
      dispatchTime,
      title,
      content,
      defaultImage,
      image,
      isPushNotificationEnabled,
      isEmailNotificationEnabled,
      status,
      projects,
      buildings,
      units,
      clients,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  export() {
    return client.getRaw(`${BASE_URL}/xlsx/`, { responseType: 'blob' });
  }

  // eslint-disable-next-line class-methods-use-this
  async download() {
    const response = await this.export();

    const { filename, blob } = repackResponseFile(response, 'news');

    download(filename, blob);
  }
}

export default new AnnouncementService();
