import { ENUM } from '@/constants/viewTypes';
import * as comparators from '@/constants/comparators';

import * as schemaItemTypes from './schemaItemTypes';
import * as dictionaryTypes from './dictionaryTypes';
import { rules } from './validationRules';

const linkedProperties = {
  NOTIFICATION: 'notificationSettings',
};

/**
 * @type {Array<import('./schema').default>}
 */

export const detailsSchema = [
  {
    type: schemaItemTypes.IMAGE_LIST,
    prop: 'coverImage',
    label: 'projects.cover_image',
    editable: true,
    onlyOne: true,
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'projects.name',
        prop: 'name',
        type: schemaItemTypes.STRING,
        rules: [rules.REQUIRED],
      },
      {
        label: 'projects.type',
        prop: 'projectType',
        type: schemaItemTypes.ENUM,
        dictionary: dictionaryTypes.PROJECT_TYPES,
        rules: [rules.REQUIRED],
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'projects.buildings_number',
        prop: 'numberOfBuildings',
        type: schemaItemTypes.NUMBER,
      },
      {
        label: 'projects.units_number',
        prop: 'numberOfUnits',
        type: schemaItemTypes.NUMBER,
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'projects.сommunity',
        prop: 'community',
        type: schemaItemTypes.STRING,
      },
      {
        label: 'projects.developer',
        prop: 'developer',
        type: schemaItemTypes.STRING,
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'projects.address',
        prop: 'address',
        type: schemaItemTypes.STRING,
      },
      {
        label: '',
        type: schemaItemTypes.TITLE,
      },
    ],
  },
  {
    label: 'projects.location',
    type: schemaItemTypes.TITLE,
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'projects.country',
        prop: 'country',
        type: schemaItemTypes.COUNTRY,
        dictionary: dictionaryTypes.COUNTRIES,
        rules: [rules.REQUIRED],
      },
      {
        label: 'projects.state',
        prop: 'state',
        type: schemaItemTypes.STRING,
        rules: [rules.REQUIRED],
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'projects.city',
        prop: 'city',
        type: schemaItemTypes.STRING,
        rules: [rules.REQUIRED],
      },
      {
        label: 'projects.postal',
        prop: 'postal',
        type: schemaItemTypes.STRING,
        rules: [rules.REQUIRED],
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'projects.company_address',
        prop: 'companyAddress',
        type: schemaItemTypes.STRING,
        rules: [rules.REQUIRED],
      },
      {
        label: 'projects.location',
        prop: 'location',
        type: schemaItemTypes.STRING,
        rules: [rules.REQUIRED],
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'projects.land_number',
        prop: 'landNumber',
        type: schemaItemTypes.STRING,
      },
      {
        label: 'projects.makani_number',
        prop: 'makaniNumber',
        type: schemaItemTypes.STRING,
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'projects.plot_number',
        prop: 'plotNumber',
        type: schemaItemTypes.STRING,
      },
      {
        label: 'projects.parcel_number',
        prop: 'parcelNumber',
        type: schemaItemTypes.STRING,
      },
    ],
  },
  {
    label: 'projects.contract',
    type: schemaItemTypes.TITLE,
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.DATE,
        prop: 'contractPeriodFrom',
        label: 'projects.contract_period_from',
        onlyFutureDate: true,
      },
      {
        type: schemaItemTypes.DATE,
        prop: 'contractPeriodTo',
        label: 'projects.contract_period_to',
        onlyFutureDate: true,
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.DATE,
        prop: 'financialPeriodFrom',
        label: 'projects.financial_period_from',
        onlyFutureDate: true,
      },
      {
        type: schemaItemTypes.DATE,
        prop: 'financialPeriodTo',
        label: 'projects.financial_period_to',
        onlyFutureDate: true,
      },
    ],
  },
  {
    label: 'projects.visitor_parking_available',
    type: schemaItemTypes.TITLE,
  },
  {
    type: schemaItemTypes.ENUM,
    viewType: ENUM.radio,
    dictionary: dictionaryTypes.APPROVAL,
    prop: 'visitorParkingAvailable',
    defaultValue: false,
  },
  {
    label: 'projects.area_unit',
    type: schemaItemTypes.TITLED_SECTION,
  },
  {
    type: schemaItemTypes.ENUM,
    viewType: ENUM.radio,
    dictionary: dictionaryTypes.AREA_UNIT,
    prop: 'areaUnit',
    defaultValue: 'm2',
  },
];

export const teamSchema = [
  {
    type: schemaItemTypes.TEAM_LIST,
    prop: 'roles',
    uniqueKey: 'id',
    children: [
      {
        type: schemaItemTypes.ROLE,
        label: 'employee.role',
        prop: 'role',
        rules: [rules.REQUIRED],
      },
      {
        type: schemaItemTypes.MANY_PERFORMERS,
        label: 'label.performers',
        prop: 'employees',
        canSelectAll: true,
        rules: [rules.MANY_REQUIRED],
      },
    ],
  },
];

export const notificationsSchema = [
  {
    label: 'notification.lease_expiration_reminder',
    prop: linkedProperties.NOTIFICATION,
    dictionary: dictionaryTypes.NOTIFICATION,
    type: schemaItemTypes.BOOLEAN,
  },
  {
    type: schemaItemTypes.ROW,
    displayConditions: {
      variables: [
        {
          from: [linkedProperties.NOTIFICATION],
          comparableValues: [true],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `${linkedProperties.NOTIFICATION}`,
    },
    children: [
      {
        label: 'notification.time_to_send',
        prop: 'at',
        type: schemaItemTypes.ENUM,
        rules: [rules.REQUIRED],
        dictionary: dictionaryTypes.TIMES,
        defaultValue: { name: '06 PM', value: '18:00' },
      },
      {
        type: schemaItemTypes.TITLE,
        label: '',
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    displayConditions: {
      variables: [
        {
          from: [linkedProperties.NOTIFICATION],
          comparableValues: [true],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `${linkedProperties.NOTIFICATION}`,
    },
    children: [
      {
        label: 'notification.interval',
        prop: 'repeatInterval',
        type: schemaItemTypes.ENUM,
        rules: [rules.REQUIRED],
        dictionary: dictionaryTypes.INTERVAL_DAYS,
        defaultValue: { name: '5 days', value: 5 },
      },
      {
        type: schemaItemTypes.TITLE,
        label: '',
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    displayConditions: {
      variables: [
        {
          from: [linkedProperties.NOTIFICATION],
          comparableValues: [true],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `${linkedProperties.NOTIFICATION}`,
    },
    children: [
      {
        label: 'notification.repetitions',
        prop: 'repeatCount',
        type: schemaItemTypes.NUMBER,
        rules: [rules.REQUIRED, rules.POSITIVE, rules.INTEGER, 'max_value:10', 'min_value:1'],
        defaultValue: 1,
      },
      {
        type: schemaItemTypes.TITLE,
        label: '',
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    displayConditions: {
      variables: [
        {
          from: [linkedProperties.NOTIFICATION],
          comparableValues: [true],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `${linkedProperties.NOTIFICATION}`,
    },
    children: [
      {
        label: 'notification.recipients',
        prop: 'recipients',
        type: schemaItemTypes.ENUM,
        viewType: ENUM.radio,
        rules: [rules.REQUIRED],
        dictionary: dictionaryTypes.RECIPIENTS,
        defaultValue: 'long_term_tenants',
      },
      {
        type: schemaItemTypes.TITLE,
        label: '',
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    displayConditions: {
      variables: [
        {
          from: ['recipients'],
          comparableValues: ['users', 'all'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: 'recipients',
    },
    children: [
      {
        label: 'notification.users_to_be_notified',
        prop: 'employees',
        type: schemaItemTypes.MANY_PERFORMERS_FOR_PROJECT,
        rules: [rules.MANY_REQUIRED],
      },
      {
        type: schemaItemTypes.TITLE,
        label: '',
      },
    ],
  },
];

export const roleAddSchema = [
  {
    type: schemaItemTypes.ROLE,
    label: 'employee.role',
    prop: 'role',
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.MANY_PERFORMERS,
    label: 'label.performers',
    prop: 'employees',
    canSelectAll: true,
    rules: [rules.MANY_REQUIRED],
  },
];

export const roleEditSchema = [
  {
    type: schemaItemTypes.ROLE,
    label: 'employee.role',
    prop: 'role',
    disabled: true,
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.MANY_PERFORMERS,
    label: 'label.performers',
    prop: 'employees',
    canSelectAll: true,
    rules: [rules.MANY_REQUIRED],
  },
];
