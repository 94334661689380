<template>
  <file-input accept="image/*" multiple @input="input">
    <template #default="{ on, attrs }">
      <v-btn
        height="80"
        width="80"
        color="secondary"
        class="form-image-upload primary--text d-flex align-center justify-center elevation-0"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
      >
        <span class="d-flex flex-column">
          <v-icon>mdi-image-plus</v-icon>
          <span class="form-image-upload__button">
            {{ $t('button.upload') }}
          </span>
        </span>
      </v-btn>
    </template>
  </file-input>
</template>

<script>
import FileInput from '@/components/FileInput.vue';

export default {
  name: 'SchemaFormImageUpload',

  components: { FileInput },

  props: {
    value: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
  },

  methods: {
    input(images) {
      this.$emit('input', images);
    },
  },
};
</script>

<style lang="scss">
.form-image-upload {
  border: 1px solid rgba($--black-color-0, 0.1);

  &__button {
    text-transform: none;
  }
}
</style>
