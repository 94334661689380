<template>
  <v-textarea
    v-if="textarea"
    v-model="modelWrapper"
    outlined
    no-resize
    :disabled="localDisabled"
    :label="label"
    :error-messages="errors"
    v-on="inheritedListeners"
  >
    <template v-if="localTooltip" #append>
      <v-tooltip top max-width="250">
        <template #activator="{ on, attrs }">
          <v-icon color="primary" v-bind="attrs" v-on="on">{{ localTooltipIcon }}</v-icon>
        </template>

        <span>{{ $t(localTooltip) }}</span>
      </v-tooltip>
    </template>
  </v-textarea>
  <text-field
    v-else
    v-model="modelWrapper"
    :label="label"
    :error-messages="errors"
    :disabled="localDisabled"
    v-on="inheritedListeners"
  >
    <template v-if="localTooltip" #append>
      <v-tooltip top max-width="250">
        <template #activator="{ on, attrs }">
          <v-icon color="primary" v-bind="attrs" v-on="on">{{ localTooltipIcon }}</v-icon>
        </template>

        <span>{{ $t(localTooltip) }}</span>
      </v-tooltip>
    </template>
  </text-field>
</template>

<script>
// Utils
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

// Components
import TextField from '@/components/TextField.vue';

export default {
  name: 'SchemaFormFieldString',

  components: { TextField },

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      required: true,
    },
    tooltip: {
      type: Object,
      default: () => ({ text: '', icon: '' }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),

    localTooltip() {
      return this.payload?.tooltip || this.tooltip.text;
    },

    localTooltipIcon() {
      return this.payload?.tooltip ? 'mdi-information' : this.tooltip.icon;
    },

    localDisabled() {
      return this.payload?.disabled || this.disabled;
    },
  },
};
</script>
