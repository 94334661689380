<template>
  <div>
    <div class="d-flex justify-space-between">
      <h1 class="text-md-h4 text-h5 mb-md-4 font-weight-bold">
        {{ title }}
      </h1>

      <div>
        <slot></slot>
      </div>
    </div>

    <span class="error--text text--caption">{{ $t('entity.required') }}</span>
  </div>
</template>

<script>
export default {
  name: 'EntityModifyHeader',

  props: {
    title: { type: String, required: true },
  },
};
</script>
