<template>
  <file-input v-if="showRow" :accept="accept" multiple @input="input">
    <template #default="{ on, attrs }">
      <v-btn
        height="80"
        width="80"
        color="secondary"
        class="form-image-upload primary--text d-flex align-center justify-center elevation-0"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
      >
        <span class="d-flex flex-column">
          <v-icon>mdi-image-plus</v-icon>
          <span class="form-image-upload__button">
            {{ $t('button.upload') }}
          </span>
        </span>
      </v-btn>
    </template>
  </file-input>
  <file-input v-else class="form-file-upload" :accept="accept" multiple @input="input">
    <template #default="{ on, attrs }">
      <v-btn
        v-if="media.isMobile"
        color="secondary"
        class="form-file-upload__button primary--text d-flex align-center justify-center elevation-0"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t(textButtonUpload) }}
        <v-icon right>mdi-text-box</v-icon>
      </v-btn>
      <div v-else class="form-file-upload__drag-drop">
        <v-icon color="blue20">mdi-text-box</v-icon>
        {{ $t(textDragAndDropUpload) }}
      </div>
    </template>
  </file-input>
</template>

<script>
import FileInput from '@/components/FileInput.vue';

export default {
  name: 'SchemaFormFileUpload',

  components: { FileInput },

  inject: ['media'],

  props: {
    value: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    accept: { type: String, default: 'image/*' },
    textButtonUpload: { type: String, default: 'input_btn.upload_file' },
    textDragAndDropUpload: { type: String, default: 'input_btn.upload_file' },
    showRow: { type: Boolean, default: false },
  },

  methods: {
    input(images) {
      this.$emit('input', images);
    },
  },
};
</script>

<style lang="scss">
.form-file-upload {
  &__button {
    width: 100%;
  }

  &__drag-drop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 120px;
    // background: $--blue-color-50;
    background: rgba(230, 235, 255, 0.5);
    border-radius: 4px;
    border: 1px dashed $--blue-color-20;
    color: $--blue-color-20;
  }
}
</style>
