/* eslint-disable  */
function createCounter() {
  let count = 0;
  return () => {
    ++count;
    return count;
  };
}

const nextCount = createCounter();

const SUCCESS = 'success';
const ERROR = 'error';

class NotificationService {
  constructor() {
    this.subscribers = {};
  }

  /**
   *
   * @param {'created' | 'removed'} event
   * @param {(item) => void} callback
   * @param {Object} param2
   * @param {boolean} param2.once
   */
  addEventListener(event, callback, { once } = {}) {
    if (!this.subscribers[event]) {
      this.subscribers[event] = [];
    }

    this.subscribers[event].push({ cb: callback, once });
  }

  removeEventListener(event, callback) {
    this.subscribers[event] = this.subscribers[event].filter(subscriber => subscriber.cb !== callback);
  }

  /**
   * @param {'created' | 'removed'} event
   * @param {any} item
   */
  dispatchEvent(event, item) {
    this.subscribers[event]?.forEach(subscriber => {
      subscriber.cb(item);

      if (subscriber.once) {
        this.removeEventListener(event, subscriber.cb);
      }
    });
  }

  /**
   * @private
   * @param {string} param0
   * @param {number=} timeout
   */
  push({ message, timeout, type = SUCCESS }) {
    const id = nextCount();
    const item = { message, type, id };
    this.dispatchEvent('created', item);

    if (timeout) {
      setTimeout(() => {
        this.remove(item);
      }, timeout);
    }

    return item;
  }

  remove(item) {
    this.dispatchEvent('removed', item);
  }

  /**
   * @param {string} message
   * @param {number=} timeout
   */
  success(message, timeout) {
    return this.push({ message, timeout, type: SUCCESS });
  }

  /**
   * @param {string} message
   * @param {number=} timeout
   */
  error(message, timeout) {
    return this.push({ message, timeout, type: ERROR });
  }
}

export default new NotificationService();
