<template>
  <div class="d-flex flex-row justify-space-between mt-10">
    <span class="text-h5 text--secondary"> {{ label }}: </span>

    <span class="text-h5 font-weight-bold flex-shrink-0"> {{ amount }} {{ $t('invoice.euro') }} </span>
  </div>
</template>

<script>
export default {
  name: 'SchemaFormTotalAmount',

  props: {
    label: { type: String, required: true },
    payload: { type: Object, required: true },
  },

  computed: {
    amount() {
      const result = this.payload.services.reduce((total, service) => {
        const amountWithVat = Number(service.amount + service.amount * (service.vat / 100));
        return total + Number(amountWithVat);
      }, 0);

      return Number.isNaN(result) ? 0 : result.toFixed(2);
    },
  },
};
</script>
