import client from '@/http/client';

// for typed fields

export function fetchProjects({ limit, offset, clientId, search }) {
  return client.get('/realty/select/projects/', { params: { limit, clientId, offset, search } });
}

export function fetchBuildings({ limit, offset, projectId, clientId, search, projectsIds }) {
  return client.get('/realty/select/buildings/', {
    params: { limit, offset, projectId, clientId, search, projectsIds },
  });
}

export function fetchManyBuildings({ limit, offset, projectId, clientId, search, projects, clients, units }, config) {
  return client.post(
    '/realty/select/buildings/',
    {
      projects,
      clients,
      units,
    },
    {
      ...config,
      params: {
        limit,
        offset,
        projectId,
        clientId,
        search,
      },
    }
  );
}

export function fetchCategories() {
  return client.get('/services/for_employees_categories/');
}

export function fetchManyProjects({ limit, offset, projectId, clientId, search, clients, units, buildings }, config) {
  return client.post(
    '/realty/select/projects/',
    {
      clients,
      units,
      buildings,
    },
    {
      ...config,
      params: {
        limit,
        offset,
        projectId,
        clientId,
        search,
      },
    }
  );
}

export function fetchManyServiceTypes(config) {
  return client.get('/payments/select/service-types/', config).then(({ results }) =>
    results.map(item => ({
      id: item.value,
      name: item.name,
    }))
  );
}

export function fetchManyUnits({ limit, offset, projectId, clientId, search, projects, buildings, clients }, config) {
  return client.post(
    '/realty/select/units/',
    {
      projects,
      buildings,
      clients,
    },
    {
      ...config,
      params: {
        limit,
        offset,
        projectId,
        clientId,
        search,
      },
    }
  );
}

export function fetchManyClients({ limit, offset, projectId, clientId, search, projects, buildings, units }, config) {
  return client.post(
    '/users/select/clients/',
    {
      projects,
      buildings,
      units,
    },
    {
      ...config,
      params: {
        limit,
        offset,
        projectId,
        clientId,
        search,
      },
    }
  );
}

export function fetchManyPerformers({ limit, offset, search, forProjects, forIssues, projectId }, config) {
  return client.get(`/users/employees/`, {
    ...config,
    params: {
      limit,
      offset,
      search,
      project: projectId,
      forProjects,
      forIssues,
    },
  });
}

export function fetchManyPaymentType(config) {
  return client.get(
    '/payments/select/payment-types/',
    {},
    {
      ...config,
    }
  );
}

export function fetchUnits(
  { limit, offset, projectId, clientId, buildingId, search, projectsIds, buildingsIds },
  config
) {
  return client.get('/realty/select/units/', {
    ...config,
    params: { limit, offset, projectId, clientId, buildingId, search, projectsIds, buildingsIds },
  });
}

export function fetchClients(
  { limit, offset, projectId, buildingId, unitId, search, isChatStarted, projectsIds, buildingsIds, unitsIds },
  config
) {
  return client.get('/users/select/clients/', {
    ...config,
    params: {
      limit,
      offset,
      projectId,
      buildingId,
      unitId,
      search,
      isChatStarted,
      projectsIds,
      buildingsIds,
      unitsIds,
    },
  });
}

export function fetchRoles({ limit, offset, search }) {
  return client.get('/users/select/roles/', { limit, offset, search });
}

export function fetchEmployees({ forIssues, search, limit, offset }, config) {
  return client.get('/users/select/employees/', {
    ...config,
    params: { forIssues, search, limit, offset },
  });
}

export function fetchContractors({ search, limit, offset }) {
  return client.get('/organizations/select/contractors/', {
    params: {
      search,
      limit,
      offset,
    },
  });
}

// for enum field

export function fetchProjectTypes() {
  return client.get('/realty/select/project-types/');
}

export function fetchPropertyTypes() {
  return client.get('/realty/select/property-types/');
}

export function fetchParkingTypes() {
  return client.get('/realty/select/parking-types/');
}

/**
 * @param {object} param0
 * @param {'contractor'} param0.form
 * @returns
 */
export function fetchCountries({ form } = {}) {
  return client.get('/organizations/select/countries/', {
    params: {
      form,
    },
  });
}

export function getInvoicePeriods() {
  return Promise.resolve([
    { name: 'invoice.period_day', value: 'day' },
    { name: 'invoice.period_month', value: 'month' },
    { name: 'invoice.period_custom', value: 'period' },
  ]);
}

export function getClientsTypes() {
  return Promise.resolve([
    { name: 'client.owner', value: 'owner' },
    { name: 'client.tenant', value: 'tenant' },
  ]);
}

export function getClientsTypesWithShortTerm() {
  return Promise.resolve([
    { name: 'client.owner', value: 'owner' },
    { name: 'client.tenant', value: 'tenant' },
    { name: 'client.short_term_tenant', value: 'short_term_tenant' },
  ]);
}

export function getServiceTypes(config) {
  return client.get('/payments/select/service-types/', config).then(({ results }) => results);
}

export function getRecurrencePeriods() {
  return client.get('/payments/select/recurrence-periods/');
}

export function getUnitiesStatus() {
  return Promise.resolve([
    { name: 'units.sold', value: 'sold' },
    { name: 'units.rented', value: 'rented' },
    { name: 'units.available', value: 'available' },
    { name: 'units.rooms_rent', value: 'rented_partially' },
  ]);
}

export function getRoomStatus() {
  return Promise.resolve([
    { name: 'units.rented', value: 'rented' },
    { name: 'units.available', value: 'available' },
  ]);
}

export function getApproval() {
  return Promise.resolve([
    { name: 'button.yes', value: true },
    { name: 'button.no', value: false },
  ]);
}

export function getAreaUnit() {
  return Promise.resolve([
    { name: 'si.m', value: 'm2' },
    { name: 'si.ft', value: 'ft' },
  ]);
}

export function getUnitsMeasurementItems() {
  return Promise.resolve([
    { name: 'dashboard.measurement_eur', value: 'eur' },
    { name: 'dashboard.measurement_pcs', value: 'pcs' },
  ]);
}

export function getAccountingItems() {
  return Promise.resolve([
    { name: 'dashboard.accounting_time', value: 0 },
    { name: 'dashboard.accounting_period', value: 1 },
  ]);
}

export function getVariantLookTasks() {
  return Promise.resolve([
    { name: 'tasks.all_tasks', value: 'allTask' },
    { name: 'tasks.only_my', value: 'onlyMy' },
  ]);
}

export function getServicesScenarioTypes() {
  return Promise.resolve([
    { name: 'services.scenario.link', value: 'link' },
    { name: 'services.scenario.file', value: 'file' },
    { name: 'services.scenario.quick_chat_message', value: 'quick_chat_message' },
  ]);
}

export function getTimes() {
  return Promise.resolve([
    { name: '12 AM', value: '00:00' },
    { name: '1 AM', value: '01:00' },
    { name: '2 AM', value: '02:00' },
    { name: '3 AM', value: '03:00' },
    { name: '4 AM', value: '04:00' },
    { name: '5 AM', value: '05:00' },
    { name: '6 AM', value: '06:00' },
    { name: '7 AM', value: '07:00' },
    { name: '8 AM', value: '08:00' },
    { name: '9 AM', value: '09:00' },
    { name: '10 AM', value: '10:00' },
    { name: '11 AM', value: '11:00' },
    { name: '12 PM', value: '12:00' },
    { name: '1 PM', value: '13:00' },
    { name: '2 PM', value: '14:00' },
    { name: '3 PM', value: '15:00' },
    { name: '4 PM', value: '16:00' },
    { name: '5 PM', value: '17:00' },
    { name: '6 PM', value: '18:00' },
    { name: '7 PM', value: '19:00' },
    { name: '8 PM', value: '20:00' },
    { name: '9 PM', value: '21:00' },
    { name: '10 PM', value: '22:00' },
    { name: '11 PM', value: '23:00' },
  ]);
}

export function getIntervalDays() {
  return Promise.resolve([
    { name: '1 day', value: 1 },
    { name: '2 days', value: 2 },
    { name: '3 days', value: 3 },
    { name: '4 days', value: 4 },
    { name: '5 days', value: 5 },
    { name: '6 days', value: 6 },
    { name: '7 days', value: 7 },
    { name: '8 days', value: 8 },
    { name: '9 days', value: 9 },
    { name: '10 days', value: 10 },
  ]);
}

export function getTypeOfRecipients() {
  return Promise.resolve([
    { name: 'notification.long_term_tenants', value: 'long_term_tenants' },
    { name: 'notification.users', value: 'users' },
    { name: 'notification.long_term_tenants_and_users', value: 'all' },
  ]);
}
